import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { logger } from './lib/logger';
import { addAction } from './lib/user-monitor';
import NotFound from './NotFound';
import './Walkthrough.css';

const log = logger('Walkthrough');

interface QuoteData {
  jobId: string;
  quoteId: string;
  firstName: string;
  lastName: string;
  repFirstName: string;
  repLastName: string;
}

function Walkthrough() {
  const params = useParams();
  const id = params.id;
  const mediaUrl = process.env.REACT_APP_MEDIA_BASE_URL;
  const quoteDataUrl = `${mediaUrl}/${id}/cp-quote.json`;
  const videoUrl = `${mediaUrl}/${id}/cp-video.mp4`;
  const [data, setData] = useState<QuoteData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    log.info('loading assets', { id, quoteDataUrl, videoUrl });
  }, [id, quoteDataUrl, videoUrl]);

  useEffect(() => {
    const setQuoteData = async () => {
      const response = await fetch(quoteDataUrl);
      if (!response.ok) {
        log.error('failed to fetch quote data', { url: quoteDataUrl }, response);
        setError('failed to fetch quote data');
        return;
      }
      const data = await response.json();
      log.info('quote data loaded', data);
      setData(data);
    };

    setQuoteData();
  }, [quoteDataUrl]);

  const handleVideoLoad = (event: React.SyntheticEvent<HTMLElement, Event>) => {
    log.info('video loaded');
    setVideoLoaded(true);
  };

  const handleVideoError = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    log.error('failed to load video', { url: videoUrl });
    setError('failed to load video');
  };

  const handleVideoStart = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    log.info('video started playing');
    addAction('Video Started');
  };

  const handleVideoPause = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    log.info('video paused');
    addAction('Video Paused');
  };

  const handleVideoEnd = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    log.info('video ended');
    addAction('Video Ended');
  };

  if (error) {
    return <NotFound />;
  }

  return (
    <div className="Walkthrough">
      <div className="header">
        <div className="header-logo">
          <a href="https://projectsolar.com">
            <span className="sr-only">Project Solar homepage</span>
          </a>
        </div>

        <div className="header-cta">
          <input type="button" className="btn" value="Checkout" />
        </div>
      </div>
      {!data || !videoLoaded ? <div className="loading-skeleton" /> : null}
      {/* render the div with display:none until we have data and the video has loaded */}
      <div
        className="center-container"
        style={!data || !videoLoaded ? { display: 'none' } : undefined}
      >
        <div className="intro">
          <h1 className="intro-title">Hi {data?.firstName}!</h1>
          <p>
            Thanks for considering Project Solar! We've made a personalized video walkthrough just
            for you, to help you understand what's included in your proposal.
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="10"
            fill="none"
            className="quote-separator"
          >
            <path
              d="M 0 1 H 12.8431 C 13.904 1 14.9214 1.4214 15.6716 2.1716 L 21.1716 7.6716 C 22.7337 9.2337 25.2663 9.2337 26.8284 7.6716 L 32.3284 2.1716 C 33.0786 1.4214 34.096 1 35.1569 1 h 100000"
              stroke="#C4CDD5"
            />
          </svg>
          <p className="quote-author">Shawn from Project Solar</p>
        </div>

        <div className="section">
          <div className="section-item">
            <div className="video-container">
              <video
                className="video"
                onLoadedData={handleVideoLoad}
                onError={handleVideoError}
                onPlay={handleVideoStart}
                onPause={handleVideoPause}
                onEnded={handleVideoEnd}
                controls
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="cta">
          <input type="button" className="btn" value="Checkout" />
          <p>Complete the checkout to access your detailed proposal and discover your savings.</p>
        </div>

        <div className="contact">
          <div className="contact-detail">
            <p>
              <a href="mailto:support@projectsolar.com">support@projectsolar.com</a>
            </p>

            <p>256 669-9660</p>
          </div>

          <div className="created-for">
            <div className="created-for-border">
              <p className="created-for-title">Created for</p>

              <p>
                {data?.firstName} {data?.lastName}
              </p>
            </div>
          </div>

          <div className="customer-rep">
            <div className="customer-rep-border">
              <p className="customer-rep-title">Customer Representative</p>

              <p>
                {data?.repFirstName} {data?.repLastName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Walkthrough;
